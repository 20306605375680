import { useState, useEffect } from "react";

const CART_KEY = "my-cart";

export default function useCart() {
    const [cartItems, setCartItems] = useState([]);
    const inCartItems = cartItems.filter(item => item.status === "in_cart");
    const awaitingOrders = cartItems.filter(item => item.status === "awaiting");
    const paidOrders = cartItems.filter(item => item.status === "paid");

    // Загрузка из localStorage при инициализации
    useEffect(() => {
        const storedCart = localStorage.getItem(CART_KEY);
        if (storedCart) {
            try {
                setCartItems(JSON.parse(storedCart));
            } catch (e) {
                console.error("Ошибка парсинга корзины:", e);
            }
        }
    }, []);

    // Сохраняем при изменении
    useEffect(() => {
        localStorage.setItem(CART_KEY, JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (product, quantity = 1, promoCode = "", address = "") => {
        setCartItems(prevItems => {
            // ищем товар с тем же id, промокодом и адресом
            const existingItem = prevItems.find(item =>
                item.id === product.id &&
                item.promoCode === promoCode &&
                item.address === address
            );

            if (existingItem) {
                // если совпадает полностью → увеличиваем количество
                return prevItems.map(item =>
                    item.id === product.id &&
                        item.promoCode === promoCode &&
                        item.address === address
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                );
            } else {
                // иначе добавляем как новый товар
                return [
                    ...prevItems,
                    {
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        currency: product.currency,
                        city: product.city,
                        country: product.country,
                        image: product.image || product.currentMedia?.[0] || null,
                        quantity,
                        status: "in_cart",
                        promoCode,
                        address
                    }
                ];
            }
        });
    };

    const updateCartItem = (originalItem, updatedFields) => {
        setCartItems(prev => {
            // 1. Найти другой элемент с таким же id, promoCode, address (будущими)
            const targetAddress = updatedFields.address;
            const targetPromo = updatedFields.promoCode;
            const targetQuantity = updatedFields.quantity;

            const duplicateItem = prev.find(item =>
                item !== originalItem &&
                item.id === originalItem.id &&
                item.promoCode === targetPromo &&
                item.address === targetAddress
            );

            // 2. Если нашли — удаляем оба, объединяем в один
            if (duplicateItem) {
                return [
                    ...prev.filter(item => item !== originalItem && item !== duplicateItem),
                    {
                        ...duplicateItem,
                        quantity: duplicateItem.quantity + targetQuantity
                    }
                ];
            }

            // 3. Иначе — обычное обновление
            return prev.map(item =>
                item === originalItem
                    ? { ...item, ...updatedFields }
                    : item
            );
        });
    };

    const removeFromCart = (id) => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const totalPrice = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const currency = cartItems[0]?.currency || '';

    return {
        cartItems,
        addToCart,
        updateCartItem,
        removeFromCart,
        clearCart,
        totalPrice,
        currency
    };
}