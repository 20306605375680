import React, { useEffect, useState } from 'react';
import styles from './CardManager.module.css';
import { notify } from '../../components/NotificationContainer/NotificationContainer';

const CARD_STORAGE_KEY = 'user-cards';

const CardManager = () => {
    const [cards, setCards] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [newCard, setNewCard] = useState({ number: '', expiry: '', cvv: '' });
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    // 📦 Загрузка из localStorage
    useEffect(() => {
        const stored = localStorage.getItem(CARD_STORAGE_KEY);
        if (stored) setCards(JSON.parse(stored));
    }, []);

    // 💾 Сохранение в localStorage
    useEffect(() => {
        localStorage.setItem(CARD_STORAGE_KEY, JSON.stringify(cards));
    }, [cards]);

    const handleAddCard = () => {
        const rawNumber = newCard.number.replace(/\s/g, '');
        const expiry = `${newCard.month}/${newCard.year}`;
        if (rawNumber.length === 16 && newCard.month && newCard.year && newCard.cvv.length === 3) {
            setCards(prev => [...prev, { ...newCard, number: rawNumber, expiry }]);
            setNewCard({ number: '', month: '', year: '', cvv: '' });
            setModalOpen(false);
        } else {
            alert("Пожалуйста, введите все данные корректно.");
        }
    };

    const handleDeleteCard = (index) => {
        setCards(prev => prev.filter((_, i) => i !== index));
    };

    const handleSelectCard = (index) => {
        setSelectedCardIndex(index); // 👈 обязательно!
        notify(`Выбрана карта: **** ${cards[index].number.slice(-4)}`);
    };

    const formatCardNumber = (value) => {
        return value
            .replace(/\D/g, '')
            .substring(0, 16)
            .replace(/(.{4})/g, '$1 ')
            .trim();
    };

    return (
        <div className={styles.cardsContainer}>
            <h3>💳 Мои карты</h3>

            {selectedCardIndex !== null && (
                <div className={styles.selectedCardDisplay}>
                    <h4>Выбранная карта</h4>
                    <div className={styles.cardVisual}>
                        <div className={styles.cardChip} />
                        <div className={styles.cardNumber}>
                            •••• •••• •••• {cards[selectedCardIndex]?.number.slice(-4)}
                        </div>
                        <div className={styles.cardMeta}>
                            <span className={styles.cardExpiry}>{cards[selectedCardIndex]?.expiry}</span>
                            <span className={styles.cardBrand}>VISA</span>
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.cardList}>
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={`${styles.cardItem} ${selectedCardIndex === index ? styles.selectedCard : ''}`}
                    >
                        <div className={styles.cardVisual}>
                            <div className={styles.cardChip} />
                            <div className={styles.cardNumber}>
                                •••• •••• •••• {card.number.slice(-4)}
                            </div>
                            <div className={styles.cardMeta}>
                                <span className={styles.cardExpiry}>{card.expiry}</span>
                                <span className={styles.cardBrand}>VISA</span> {/* или Mastercard */}
                            </div>
                        </div>

                        <div className={styles.cardActions}>
                            <button className={styles.selectButton} onClick={() => handleSelectCard(index)}>Выбрать</button>
                            <button className={styles.deleteButton} onClick={() => handleDeleteCard(index)}>Удалить</button>
                        </div>
                    </div>
                ))}
            </div>

            <button className={styles.addButton} onClick={() => setModalOpen(true)}>
                ➕ Добавить ещё
            </button>

            {modalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <h3>Добавить карту</h3>

                        <input
                            type="text"
                            placeholder="Номер карты"
                            maxLength={19}
                            value={formatCardNumber(newCard.number)}
                            onChange={(e) => setNewCard({ ...newCard, number: e.target.value })}
                        />

                        <div className={styles.expiryWrapper}>
                            <select
                                value={newCard.month || ''}
                                onChange={(e) => setNewCard({ ...newCard, month: e.target.value })}
                            >
                                <option value="">Месяц</option>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={String(i + 1).padStart(2, '0')}>
                                        {String(i + 1).padStart(2, '0')}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={newCard.year || ''}
                                onChange={(e) => setNewCard({ ...newCard, year: e.target.value })}
                            >
                                <option value="">Год</option>
                                {Array.from({ length: 10 }, (_, i) => {
                                    const year = new Date().getFullYear() + i;
                                    return <option key={i} value={String(year).slice(-2)}>{year}</option>;
                                })}
                            </select>
                        </div>

                        <input
                            type="password"
                            placeholder="CVV"
                            maxLength={3}
                            value={newCard.cvv}
                            onChange={(e) => setNewCard({ ...newCard, cvv: e.target.value })}
                        />

                        <div className={styles.modalButtons}>
                            <button onClick={handleAddCard} className={styles.saveButton}>
                                💾 <span>Сохранить</span>
                            </button>
                            <button onClick={() => setModalOpen(false)} className={styles.cancelButton}>
                                ❌ <span>Отмена</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CardManager;