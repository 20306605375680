import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

export default function WhatsAppAuth() {
    const [qr, setQr] = useState(null);
    const [error, setError] = useState(null);

    const sendMultipleMessages = async () => {
        try {
            const response = await axios.post('http://localhost:4000/send-multiple', {
                number: '77079483176',
                message: 'Привет из сервиса!',
                count: 3,
                interval: 5000
            });

            console.log(response.data);
        } catch (error) {
            console.error('Ошибка:', error);
        }
    };

    const sendContactsToNumber = async () => {
        try {
            const { data } = await axios.get('http://localhost:4000/contacts');

            if (data.success && data.contacts.length > 0) {
                const contactsText = data.contacts
                    .map((c, i) => `${i + 1}. ${c.name} (${c.number})`)
                    .join('\n');

                await axios.post('http://localhost:4000/send', {
                    number: '77079483176',
                    message: `Мои контакты WhatsApp:\n\n${contactsText}`
                });

                console.log('Контакты отправлены');
            } else {
                console.log('Нет контактов');
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
    };

    useEffect(() => {
        const fetchQR = async () => {
            try {
                const { data } = await axios.get('http://localhost:4000/qr');

                if (data && data.qr) {
                    setQr(data.qr);
                    setError(null);
                } else {
                    setQr(null);
                    setError('QR-код отсутствует в ответе сервера');
                }

            } catch (error) {
                console.error('Ошибка запроса:', error);
                setError('Ошибка получения QR-кода от сервера');
            }
        };

        fetchQR();
        const interval = setInterval(fetchQR, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h1>Подключи WhatsApp</h1>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {qr ? <QRCodeSVG value={qr} /> : !error && 'Ожидание QR-кода...'}

            <button onClick={sendMultipleMessages}>Отправить сообщения</button>
            <button onClick={sendContactsToNumber}>Отправить мои контакты</button>
        </div>
    );
}