import { useState, useRef } from "react";
import styles from './ProductModal.module.css';
import Notification from "../../components/Notification/Notification";
import { notify } from "../../components/NotificationContainer/NotificationContainer";
import ChatButton from '../../components/ChatButton/ChatButton';
import OrderFlow from "./OrderFlow";
import COUNTRIES from "../../data/countriesData";

export default function ProductModal({
    product,
    onClose,
    addToCart,
    updateCartItem,
    initialPromoCode = "",
    initialAddress = "",
    isEdit = false
}) {
    const [showNotif, setShowNotif] = useState(false);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const cartButtonRef = useRef(null);

    const [promoCode, setPromoCode] = useState(initialPromoCode);
    const [deliveryAddress, setDeliveryAddress] = useState(initialAddress);
    const [isSelfPickup, setIsSelfPickup] = useState(initialAddress === "Самовывоз");
    const [quantity, setQuantity] = useState(product.quantity || 1);

    const [withAmbassadorPromo, setWithAmbassadorPromo] = useState(false);
    const [promoError, setPromoError] = useState(false);

    const media = product.media || [{ type: "image", src: product.image }];
    const currentMedia = media[currentMediaIndex];

    const handlePrev = () => {
        setCurrentMediaIndex((prev) =>
            prev === 0 ? media.length - 1 : prev - 1
        );
    };
    const handleNext = () => {
        setCurrentMediaIndex((prev) =>
            (prev + 1) % media.length
        );
    };

    const increaseQuantity = () => setQuantity((prev) => prev + 1);
    const decreaseQuantity = () => setQuantity((prev) => Math.max(1, prev - 1));

    const handleAddToCart = (e) => {
        e.stopPropagation();

        const addressToSave = isSelfPickup ? "Самовывоз" : deliveryAddress;

        addToCart?.(product, quantity, promoCode, addressToSave);
        notify(`🛒 ${product.name} — ${quantity} шт.`, 5000);

        // 🔽 Анимация ящика 
        const box = document.createElement("div");
        box.textContent = "📦";
        box.className = styles.flyBox;
        document.body.appendChild(box);

        const from = e.currentTarget.getBoundingClientRect();
        const to = document.querySelector(".cart-float-button")?.getBoundingClientRect();

        if (to) {
            box.style.left = `${from.left + from.width / 2}px`;
            box.style.top = `${from.top + from.height / 2}px`;

            requestAnimationFrame(() => {
                box.style.transform = `translate(${to.left - from.left}px, ${to.top - from.top}px) scale(0.3)`;
                box.style.opacity = "0";
            });

            setTimeout(() => {
                document.body.removeChild(box);
            }, 1000);
        }

        // 🔽 Показываем уведомление
        setShowNotif(true);
    };

    const handleUpdateCart = () => {
        const addressToSave = isSelfPickup ? "Самовывоз" : deliveryAddress;

        updateCartItem?.(product, {
            promoCode,
            address: addressToSave,
            quantity
        });

        notify(`✅ ${product.name} обновлён`, 5000);
        onClose();
    };

    // Адрес в CartModel 
    const [addressError, setAddressError] = useState(false);
    const handlePayment = () => {
        const address = isSelfPickup ? "Самовывоз" : deliveryAddress.trim();

        if (!address) {
            notify("❗ Введите адрес доставки", 4000);
            setAddressError(true);
            setTimeout(() => setAddressError(false), 1000);
            return;
        }

        if (!promoCode.trim()) {
            notify("❗ Укажите промокод (логин амбассадора)", 4000);
            setPromoError(true);
            setTimeout(() => setPromoError(false), 1000);
            return;
        }

        notify(`✅ Заявка оформлена: ${product.name}, ${quantity} шт.`, 4000);
        alert(`Оплата товаров\nДоставка: ${address}\nПромокод: ${promoCode}`);

        // Очистка
        setDeliveryAddress("");
        setPromoCode("");
    };

    // Отзывы 
    const [reviewText, setReviewText] = useState("");
    const [reviewRating, setReviewRating] = useState(0); // звёздочки
    const [reviews, setReviews] = useState([
        {
            author: "Алия",
            country: "KAZAKHSTAN",
            rating: 5,
            time: "2 часа назад",
            text: "Всё пришло быстро, упаковка была отличная!",
        },
        {
            author: "Ержан",
            country: "RUSSIA",
            rating: 4,
            time: "вчера",
            text: "Курьер опоздал на 10 минут, но в остальном всё чётко.",
        },
        {
            author: "NikitaDev",
            country: "UKRAINE",
            rating: 5,
            time: "3 дня назад",
            text: "Товар оригинальный, продавец ответственный 👍",
        },
    ]);




    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.modalCloseIcon} onClick={onClose} aria-label="Закрыть модальное окно">
                    ×
                </button>

                <div className={styles.modalBody}>
                    {/* Медиа слева */}
                    <div className={styles.leftColumn}>
                        <div className={styles.sellerInfo}>
                            <p>
                                <strong>Продавец:</strong>{" "}
                                {product.sellerType === "legal" ? "🏢 Юридическое лицо" : "🧑 Физическое лицо"} — <strong>{product.seller}</strong>
                            </p>

                            {/* Добавление кнопки чата с продавцом */}
                            <ChatButton userId={product.sellerId} type="seller" />
                        </div>

                        <div className={styles.mediaWrapper}>
                            <button className={`${styles.sliderBtn} ${styles.left}`} onClick={handlePrev}>&lt;</button>

                            {currentMedia.type === "image" ? (
                                <img src={currentMedia.src} alt="product" className={styles.mediaItem} />
                            ) : (
                                <video
                                    src={currentMedia.src}
                                    className={styles.mediaItem}
                                    muted
                                    loop
                                    autoPlay
                                    controls
                                />
                            )}

                            <button className={`${styles.sliderBtn} ${styles.right}`} onClick={handleNext}>&gt;</button>
                        </div>

                        {/* Рейтинг и отзыв — под медиа */}
                        <div className={styles.sellerPanel}>
                            <div className={styles.sellerRating}>
                                <p><strong>Рейтинг продавца:</strong></p>
                                <div className={styles.stars}>
                                    ⭐⭐⭐⭐☆ <span className={styles.ratingValue}>4.2 / 5</span>
                                </div>
                                <p className={styles.ratingVotes}>на основе 128 отзывов</p>
                            </div>

                            <p><strong>Отзывы:</strong></p>
                            <div className={styles.reviewsList}>
                                {reviews.map((r, i) => {
                                    const country = COUNTRIES.find(c => c.value === r.country);
                                    return (
                                        <div key={i} className={styles.reviewItem}>
                                            <div className={styles.reviewHeader}>
                                                <div className={styles.authorInfo}>
                                                    <span className={styles.reviewAuthor}>{r.author}</span>
                                                    {country && (
                                                        <span className={styles.country}>
                                                            <img
                                                                src={`https://flagcdn.com/h20/${r.country.toLowerCase()}.png`}
                                                                alt={country.label}
                                                                className={styles.flag}
                                                            />
                                                            {country.label}
                                                        </span>
                                                    )}
                                                </div>
                                                <span className={styles.reviewTime}>{r.time}</span>
                                            </div>
                                            <div className={styles.reviewStars}>
                                                {Array.from({ length: 5 }).map((_, starIndex) => (
                                                    <span key={starIndex}>
                                                        {starIndex < r.rating ? "⭐" : "☆"}
                                                    </span>
                                                ))}
                                            </div>
                                            <p className={styles.reviewText}>{r.text}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <form
                                className={styles.reviewForm}
                                onSubmit={(e) => {
                                    e.preventDefault();

                                    const newReview = {
                                        author: "Аноним", // или текущий пользователь
                                        country: "KAZAKHSTAN", // можно заменить динамически
                                        rating: reviewRating,
                                        time: "только что",
                                        text: reviewText,
                                    };

                                    setReviews((prev) => [...prev, newReview]);
                                    setReviewText("");
                                    setReviewRating(0);
                                }}
                            >
                                <label htmlFor="review">Оставить отзыв:</label>

                                {/* Звёзды */}
                                <div className={styles.starsInput}>
                                    {Array.from({ length: 5 }).map((_, i) => (
                                        <span
                                            key={i}
                                            onClick={() => setReviewRating(i + 1)}
                                            style={{
                                                cursor: "pointer",
                                                fontSize: "20px",
                                                color: i < reviewRating ? "#f5a623" : "#ccc",
                                            }}
                                        >
                                            {i < reviewRating ? "⭐" : "☆"}
                                        </span>
                                    ))}
                                </div>

                                <textarea
                                    id="review"
                                    className={styles.textarea}
                                    placeholder="Ваш отзыв..."
                                    rows="3"
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                />
                                <button type="submit" className={styles.submitReviewBtn}>Отправить</button>
                            </form>
                        </div>
                    </div>


                    {/* Информация справа */}
                    <div className={styles.modalInfo}>
                        <h2>{product.name}</h2>

                        {/* Блок статуса */}
                        <OrderFlow product={product} />

                        <p><strong>Цена:</strong> {product.price} {product.currency}</p>
                        <p><strong>Описание:</strong> {product.description || "—"}</p>
                        <p><strong>Наличие:</strong> {product.available ? "В наличии" : "Нет в наличии"}</p>
                        <p><strong>Продавец:</strong> +7 747 999 88 11</p>
                        <p><strong>Город:</strong> {product.city}</p>
                        <p><strong>Страна:</strong> {product.country}</p>
                        <p><strong>Просмотры:</strong> {product.watch_count || "—"}</p>
                        <p><strong>Дата публикации:</strong> {product.published_at || "—"}</p>

                        <div className={styles.quantityWrapper}>
                            <button className={styles.quantityBtn} onClick={decreaseQuantity}>−</button>
                            <div className={styles.quantityDisplay}>{quantity}</div>
                            <button className={styles.quantityBtn} onClick={increaseQuantity}>+</button>
                        </div>

                        <div className={styles.selfPickupCheckbox}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isSelfPickup}
                                    onChange={(e) => setIsSelfPickup(e.target.checked)}
                                />
                                <span>Я хочу забрать товар самовывозом</span>
                            </label>
                            <p className={styles.selfPickupNote}>
                                Если выбран самовывоз, адрес доставки не требуется.<br /><br />

                                Если вы выбрали заявку с самовывозом, продавец не сможет выставить счёт с резервированием средств покупателя на счёте ad_SERVLET.
                                В этом случае расчёт между продавцом и покупателем будет произведён напрямую при самовывозе товара.
                            </p>
                        </div>

                        <input
                            type="text"
                            placeholder="Введите адрес доставки"
                            value={deliveryAddress}
                            onChange={(e) => setDeliveryAddress(e.target.value)}
                            disabled={isSelfPickup}
                            className={`${styles.addressInput} ${addressError ? styles.addressInputError : ''}`}
                        />
                        <div className={styles.selfPickupCheckbox}>
                            <p className={styles.selfPickupNote}>
                                Вставьте в поле промокод (логин) амбассадора. <br />
                                Если вы являетесь амбассадором — укажите свой логин. <br />
                                Если вы не являетесь амбассадором данной компании, перейдите в раздел "Амбассадоры",
                                выберите понравившегося амбассадора, скопируйте его логин и вставьте в это поле.<br /><br />

                                Ваш промокод (логин) действует 3 раза в месяц. <br />
                                После исчерпания лимита вы можете использовать промокоды других амбассадоров.<br /><br />

                                Стать амбассадором очень просто: перейдите в раздел "Юридические лица", выберите компании, <br />
                                которые вы хотите продвигать или в которых планируете совершать покупки.
                            </p>
                        </div>
                        <input
                            type="text"
                            placeholder="Подача заявки с промокодом обязательно"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            className={`${styles.addressInput} ${promoError ? styles.addressInputError : ''}`}
                        />

                        <div className={styles.modalButtons}>
                            <button onClick={handlePayment} className={styles.modalClose}>💳 Отправить заявку</button>
                            {isEdit ? (
                                <button onClick={handleUpdateCart} className={styles.modalClose}>
                                    💾 Сохранить изменения
                                </button>
                            ) : (
                                <button ref={cartButtonRef} onClick={handleAddToCart} className={styles.modalClose}>
                                    🛒 В корзину
                                </button>
                            )}
                        </div>

                        {showNotif && (
                            <Notification
                                message={`🛒 ${product.name} — ${quantity} шт.`}
                                duration={4000}
                                onClose={() => setShowNotif(false)}
                            />
                        )}

                        <p>
                            <strong>🚚 Стоимость доставки рассчитывается отдельно.</strong><br />
                            Подайте заявку — продавец свяжется с вами, рассчитает цену и подберёт доставщика. После этого счёт будет выставлен в разделе <strong>«Моя корзина»</strong>.<br /><br />

                            💳 <strong>Вы сможете оплатить заказ или отказаться от него.</strong><br />
                            Оплата резервируется в системе и списывается только <em>после подтверждения</em> передачи товара доставщику.<br /><br />

                            ✅ <strong>Когда продавец подтвердит передачу, в вашей карточке заказа появится код.</strong><br />
                            Как только продавец отметит передачу — вы получите специальный <strong>код подтверждения</strong> в карточке заказа.<br /><br />

                            📦 Предъявите его доставщику при получении — и заберите товар.<br /><br />

                            <div className={styles.warningBlock}>
                                <p>
                                    ⚠️ <strong>Важно:</strong> перед получением обязательно проверьте <strong>наличие</strong> и <strong>состояние товара</strong>.<br />
                                    Если товар вас не устраивает или он повреждён — в карточке заказа нажмите кнопку <strong>«Детали»</strong>, где можно подать заявку на возврат средств в течение <strong>3 часов</strong> с момента получения.<br /><br />

                                    📸 Для подтверждения проблемы сфотографируйте товар и свяжитесь с продавцом через тот же раздел. Также, можете прикрепить фотографии и отправить <em>в этом же</em> разделе.<br /><br />

                                    ‼️ <strong>Важно:</strong> обе стороны — и продавец, и покупатель — обязаны сохранить все необходимые скриншоты и номер заявки (<strong>«Тикет»</strong>) в течение <strong>14 дней</strong> на случай спорных или проблемных ситуаций.
                                </p>
                            </div>

                            <div className={styles.warningBlock}>
                                <h4>Возврат и риски</h4>
                                <p>
                                    Средства за товар зачисляются продавцу в течение <strong>3 часов</strong> после ввода кода курьером.<br /><br />

                                    Если покупатель инициирует возврат, средства будут возвращены в течение <strong>3 часов</strong> — это гарантированное время,
                                    предоставленное для тщательной проверки полученного товара.<br /><br />

                                    Возврат осуществляется тем же способом, за счёт покупателя.<br /><br />

                                    Также товар можно вернуть в течение <strong>14 дней</strong> с момента получения, если он не был в эксплуатации и сохранил товарный вид.<br /><br />
                                    В этом случае покупатель и продавец обязаны самостоятельно урегулировать спор. Покупатель должен предоставить <strong>доказательства неисправности или несоответствия товара</strong>.<br /><br />
                                    Это соответствует <strong>Закону «О защите прав потребителей»</strong> — "Право потребителя на возврат или обмен товара надлежащего качества".

                                    Клиент получит свои средства обратно только после того, как продавец получит товар.<br />
                                    Если возврат не был оформлен в течение 3 часов, средства автоматически будут зачислены продавцу.<br /><br />

                                    <p><strong>Обратите внимание:</strong> система <code>ad_SERVLET</code> не несёт ответственности за возможные проблемы, возникшие в процессе передачи товара.<br />
                                        Все риски, связанные с упаковкой, передачей и безопасностью товара, лежат на продавце и клиенте при возврате.</p>
                                </p>
                            </div>
                        </p>


                    </div>
                </div>
            </div>
        </div >
    );
}