export const categoriesData = [
    { label: "Электроника", value: "electronics" },
    { label: "Хозяйство и Дом", value: "home" },
    { label: "Недвижимость", value: "apartment" },
    { label: "Офисы", value: "offices" },
    { label: "Коммерческая недвижимость", value: "commercial" },
    { label: "Склады", value: "warehouses" },
    { label: "Гаражи и паркинги", value: "garages" },
    { label: "Промышленность и Бизнес", value: "business" },
    { label: "Авто и транспорт", value: "transport" },
    { label: "Работа", value: "jobs" },
    { label: "Услуги", value: "services" },
    { label: "Животные", value: "animals" }
];

export const subCategoriesData = {
    electronics: [
        { label: "Бытовая техника", value: "home_appliances" },
        { label: "Холодильники и морозильники", value: "fridges" },
        { label: "Стиральные и сушильные машины", value: "washing_machines" },
        { label: "Плиты, духовки, варочные панели", value: "stoves" },
        { label: "Микроволновки", value: "microwaves" },
        { label: "Пылесосы", value: "vacuum_cleaners" },
        { label: "Кондиционеры и обогреватели", value: "air_conditioners" },
        { label: "Водонагреватели", value: "water_heaters" },
        { label: "Другая техника", value: "other_appliances" },
        { label: "Компьютеры", value: "computers" },
        { label: "Ноутбуки", value: "laptops" },
        { label: "ПК и комплектующие", value: "pc_parts" },
        { label: "Мониторы", value: "monitors" },
        { label: "Программное обеспечение", value: "software" },
        { label: "Аксессуары", value: "accessories" },
        { label: "Телефоны и аксессуары", value: "phones" },
        { label: "Смартфоны", value: "smartphones" },
        { label: "Кнопочные телефоны", value: "button_phones" },
        { label: "Планшеты", value: "tablets" },
        { label: "Чехлы, защитные стекла", value: "cases" },
        { label: "Зарядные устройства, кабели", value: "chargers" },
        { label: "Гарнитуры и наушники", value: "headsets" },
        { label: "Оргтехника", value: "office_equipment" },
        { label: "Принтеры и МФУ", value: "printers" },
        { label: "Сканеры", value: "scanners" },
        { label: "Картриджи и тонеры", value: "cartridges" },
        { label: "Проекторы", value: "projectors" },
        { label: "Другая техника", value: "other_electronics" }
    ],
    home: [
        { label: "Мебель (диваны, шкафы, кровати, стулья и т.д.)", value: "furniture" },
        { label: "Строительство и ремонт (инструменты, стройматериалы)", value: "construction" },
        { label: "Сад и огород (семена, саженцы, инструменты)", value: "garden" },
        { label: "Товары для дома (декор, посуда, текстиль)", value: "home_goods" }
    ],
    apartment: [
        // 🔹 Типы жилья
        { label: "Квартира", value: "flat" },
        { label: "Элитная квартира / апартаменты", value: "elite_apartment" },
        { label: "Студия", value: "studio" },
        { label: "Лофт", value: "loft" },
        { label: "Пентхаус", value: "penthouse" },
        { label: "Таунхаус", value: "townhouse" },
        { label: "Дом", value: "house" },
        { label: "Коттедж", value: "cottage" },
        { label: "Дача", value: "dacha" },
        { label: "Усадьба", value: "manor" },
        { label: "Особняк", value: "mansion" },
        { label: "Вилла", value: "villa" },
        { label: "Частный дом", value: "private_house" },
        { label: "Малоэтажный дом", value: "lowrise_house" },
        { label: "Многоквартирный дом", value: "multifamily_house" },

        // 🔹 По формату и расположению
        { label: "Жильё в новостройке", value: "new_building" },
        { label: "Жильё на вторичном рынке", value: "secondary_market" },
        { label: "Жильё в загородном посёлке", value: "village_property" },
        { label: "Комната (в квартире или коммуналке)", value: "room" },
        { label: "Апартаменты (отельного типа)", value: "hotel_apartment" }
    ],
    offices: [
        { label: "Офис", value: "office" },
        { label: "Бизнес-центр (БЦ)", value: "business_center" },
        { label: "Коворкинг", value: "coworking" },
        { label: "Офис в жилом доме", value: "office_residential" },
        { label: "Офис в административном здании", value: "office_admin_building" },
        { label: "Офис в торговом центре", value: "office_mall" },
        { label: "Офис на производственной территории", value: "office_industrial" },
        { label: "Гибкий офис / Flex-офис", value: "flex_office" },
        { label: "Open space (офис открытого типа)", value: "open_space" },
        { label: "Офис с отделкой (под ключ)", value: "office_finished" },
        { label: "Офис без отделки (shell & core)", value: "office_unfinished" },
        { label: "Класс A — престижные офисы в центре", value: "class_a_office" },
        { label: "Класс B — качественные, но попроще", value: "class_b_office" },
        { label: "Класс C — базовые помещения", value: "class_c_office" }
    ],
    commercial: [
        { label: "Помещение свободного назначения", value: "multi_purpose" },
        { label: "Торгово-офисное помещение", value: "retail_office" },
        { label: "Бизнес-особняк / особняк под офис", value: "business_mansion" },
        { label: "Отдельно стоящее здание (ОСЗ)", value: "freestanding_building" },
        { label: "Пентхаус под офис", value: "office_penthouse" }
    ],
    warehouses: [
        { label: "Складское помещение", value: "warehouse" },
        { label: "Отапливаемый склад", value: "heated_warehouse" },
        { label: "Неотапливаемый склад", value: "non_heated_warehouse" },
        { label: "Сухой склад", value: "dry_warehouse" },
        { label: "Холодильный склад / морозильный склад", value: "cold_storage" },
        { label: "Склад с температурным режимом", value: "temperature_controlled" },
        { label: "Автоматизированный склад", value: "automated_warehouse" },
        { label: "Склад ответственного хранения", value: "bonded_warehouse" },
        { label: "Мини-склад / склад-ячейка", value: "mini_warehouse" },
        { label: "Мобильный склад (модульный)", value: "mobile_warehouse" },
        { label: "Склад в капитальном здании", value: "capital_building_warehouse" },
        { label: "Склад в ангаре", value: "hangar_warehouse" },
        { label: "Склад в металлическом боксе", value: "metal_box_warehouse" },
        { label: "Склад на подземном уровне", value: "underground_warehouse" },
        { label: "Открытая складская площадка", value: "open_storage" },
        { label: "Логистический центр", value: "logistics_center" },
        { label: "Склад-кроссдокинг", value: "cross_docking" },
        { label: "Склад с пандусом", value: "ramp_warehouse" },
        { label: "Склад с ж/д путями", value: "rail_access_warehouse" },
        { label: "Склад с рампой", value: "dock_warehouse" },
        { label: "Склад с кран-балкой", value: "crane_warehouse" },
        { label: "Класс A — современные склады", value: "class_a_warehouse" },
        { label: "Класс B — хорошие склады", value: "class_b_warehouse" },
        { label: "Класс C — переделанные помещения", value: "class_c_warehouse" },
        { label: "Класс D — старые склады", value: "class_d_warehouse" }
    ],
    garages: [
        { label: "Гараж (капитальный)", value: "garage_capital" },
        { label: "Гараж (металлический / сборный)", value: "garage_metal" },
        { label: "Машиноместо", value: "parking_spot" },
        { label: "Бокс", value: "box_garage" },
        { label: "Гараж на один автомобиль", value: "garage_single" },
        { label: "Гараж на два и более автомобилей", value: "garage_double" },
        { label: "Отдельно стоящий гараж", value: "garage_freestanding" },
        { label: "Гараж в ГСК", value: "garage_gsk" },
        { label: "Гараж в жилом доме", value: "garage_residential" },
        { label: "Подземный паркинг", value: "underground_parking" },
        { label: "Наземный паркинг", value: "surface_parking" },
        { label: "Многоуровневый паркинг", value: "multi_level_parking" },
        { label: "Отапливаемый гараж", value: "garage_heated" },
        { label: "Неотапливаемый гараж", value: "garage_unheated" },
        { label: "Гараж с ямой", value: "garage_with_pit" },
        { label: "Гараж с подвалом / кладовой", value: "garage_with_storage" },
        { label: "Гараж с автоматическими воротами", value: "garage_auto_gates" },
        { label: "Гараж с электричеством", value: "garage_with_electricity" },
        { label: "Навес", value: "carport" },
        { label: "Стояночное место на охраняемой территории", value: "secure_parking" },
        { label: "Кладовка/хозблок при гараже", value: "garage_with_closet" }
    ],
    business: [
        { label: "Оборудование (станки, генераторы)", value: "equipment" },
        { label: "Сырье и материалы", value: "raw_materials" },
        { label: "Торговое оборудование", value: "trade_equipment" },
        { label: "Готовый бизнес", value: "ready_business" }
    ],
    transport: [
        { label: "Легковые авто", value: "cars" },
        { label: "Грузовики и спецтехника", value: "trucks" },
        { label: "Мотоциклы и скутеры", value: "motorcycles" },
        { label: "Запчасти и аксессуары", value: "car_parts" }
    ],
    jobs: [
        { label: "Вакансии", value: "vacancies" },
        { label: "Резюме", value: "resumes" }
    ],
    services: [
        { label: "Ремонт и строительство", value: "repair" },
        { label: "Курьерские услуги", value: "courier" },
        { label: "Обучение и репетиторство", value: "education" },
        { label: "Красота и здоровье", value: "beauty" }
    ],
    animals: [
        { label: "Домашние животные (кошки, собаки, грызуны, птицы, рептилии)", value: "pets" },
        { label: "Сельскохозяйственные животные (коровы, лошади, овцы, козы, свиньи, птица)", value: "farm_animals" },
        { label: "Рыбки и аквариумы", value: "fish" },
        { label: "Товары для животных (корм, аксессуары, вольеры, клетки)", value: "pet_goods" },
        { label: "Вязка и услуги", value: "breeding" }
    ]
};
