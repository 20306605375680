import { useEffect, useState } from "react";
import styles from "./Notification.module.css";

const Notification = ({ message = "Добавлено в корзину", duration = 3000, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            if (onClose) onClose();
        }, duration);
        return () => clearTimeout(timer);
    }, [duration, onClose]);

    if (!visible) return null;

    return (
        <div className={styles.notification}>
            {message.split('\n').map((line, i) => (
                <div key={i}>{line}</div>
            ))}
            <div className={styles.progress} style={{ animationDuration: `${duration}ms` }} />
        </div>
    );
};

export default Notification;