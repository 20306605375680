export const mockSavedDataList = [
    {
        category: "Электроника",
        subCategory: "Смартфоны",
        title: "iPhone 13 Pro Max 256GB",
        description: "Отличное состояние, полный комплект, почти не пользовались.",
        country: "Казахстан",
        city: "Алматы",
        phoneNumbers: "+7 777 123 4567",
        price: "450000",
        currency: "KZT",
        active: true,
        notificationCount: 3,
        features: ["lightning1", "crown"]
    },
    {
        category: "Авто и транспорт",
        subCategory: "Легковые авто",
        title: "Toyota Camry 2018",
        description: "Пробег 90,000 км, обслужена у дилера. Один владелец.",
        country: "Казахстан",
        city: "Нур-Султан",
        phoneNumbers: "+7 701 765 4321, +7 702 333 2211",
        price: "9500000",
        currency: "KZT",
        active: false,
        notificationCount: 0,
        features: ["fire", "crown"]
    },
    {
        category: "Услуги",
        subCategory: "Ремонт и строительство",
        title: "Ремонт квартир под ключ",
        description: "Все виды ремонтных работ. Качество, гарантия, договор.",
        country: "Казахстан",
        city: "Шымкент",
        phoneNumbers: "+7 747 555 1122",
        price: "Договорная",
        currency: "KZT",
        active: true,
        notificationCount: 1,
        features: ["heart", "lightning1"]
    }
];
