import React, { useState, useEffect } from 'react';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import styles from './ChatPage.module.css';

const ChatPage = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={styles.chatPage}>
      <div className={styles.chatContainer}>
        {isMobile ? (
          selectedChat ? (
            <div className={styles.chatWindowWrapper}>
              <button className={styles.backButton} onClick={() => setSelectedChat(null)}>
                ← Назад
              </button>
              <ChatWindow chat={selectedChat} />
            </div>
          ) : (
            <ChatSidebar onSelectChat={setSelectedChat} selectedChat={selectedChat} />
          )
        ) : (
          <>
            <ChatSidebar onSelectChat={setSelectedChat} selectedChat={selectedChat} />
            <ChatWindow chat={selectedChat} />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatPage;