import styles from "./Ambassadors.module.css";
import placeholder from "../../assets/images/Ambassador/placeholder.jpg"; // путь к фото-аватару

const AmbassadorsPage = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.list}>
                <div className={styles.inviteGrid}>
                    {[
                        { name: "Дарис", rating: 5 },
                        { name: "Сергей", rating: 3 },
                        { name: "Аманжол", rating: 4 },
                        { name: "Жансая", rating: 2 },
                        { name: "Сергей", rating: 1 },
                        { name: "Аманжол", rating: 5 },
                        { name: "Жансая", rating: 0 }
                    ].map((amb, index) => (
                        <div key={index} className={styles.card}>
                            <img className={styles.avatar} src={placeholder} alt={amb.name} />
                            <div className={styles.name}>{amb.name}</div>
                            <div className={styles.stars}>
                                {Array.from({ length: 5 }).map((_, i) => (
                                    <span key={i}>
                                        {i < amb.rating ? "★" : "☆"}
                                    </span>
                                ))}
                            </div>
                            <div className={styles.login}><p>login</p></div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

};

export default AmbassadorsPage;