import React from 'react';
import styles from './AdsSidebar.module.css';

const AdsSidebar = ({ variant = 'sidebar' }) => {
    if (variant === 'bottom') {
        return (
            <div className={styles.bottomAdsContainer}>
                <h4 className={styles.adsHeading}>📢 Рекламные предложения</h4>
                <div className={styles.bottomAds}>
                    <div className={styles.adItem}>🔥 Реклама 1</div>
                    <div className={styles.adItem}>🎯 Реклама 2</div>
                    <div className={styles.adItem}>💼 Реклама 3</div>
                </div>
            </div>
        );
    }

    // default — sidebar
    return (
        <aside className={styles.sidebar}>
            <h4>📢 Реклама</h4>
            <p>Лучшие предложения здесь!</p>
            <img src="/banner.png" alt="Реклама" className={styles.adBanner} />
        </aside>
    );
};

export default AdsSidebar;