import React, { useState, useEffect, useRef } from 'react';
import MessageBubble from './MessageBubble';
import StatusBlock from './StatusBlock';
import styles from './ChatWindow.module.css';

const ChatWindow = ({ chat }) => {
    const [messages, setMessages] = useState([
        {
            id: 1,
            type: 'status',
            from: 'user',
            statusKey: 'created',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                login: 'user_alpha',
                city: 'Алматы',
                address: 'ул. Панфилова, д. 10'
            },
            time: new Date().toISOString()
        },
        {
            id: 2,
            type: 'text',
            from: 'user',
            text: 'Здравствуйте! Я оформил заказ',
            time: new Date().toISOString()
        },
        {
            id: 3,
            type: 'status',
            from: 'seller',
            statusKey: 'invoice',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                invoice: 8000,
                amount: 75000,
                currency: '₸',
                login: 'user_alpha',
                city: 'Алматы'
            }
        },
        {
            id: 4,
            type: 'text',
            from: 'seller',
            text: 'Ожидаем оплату',
        },
        {
            id: 5,
            type: 'status',
            from: 'seller',
            statusKey: 'waiting',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                invoice: 8000,
                amount: 75000,
                currency: '₸',
                login: 'user_alpha',
                city: 'Алматы',
                time: new Date().toISOString()
            }
        },
        {
            id: 6,
            type: 'status',
            from: 'seller',
            statusKey: 'shipping',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                invoice: 8000,
                amount: 75000,
                currency: '₸',
                login: 'user_alpha',
                city: 'Алматы',
                address: 'ул. Панфилова, д. 10',
                files: ['https://via.placeholder.com/200x120?text=Фото+1']
            }
        },
        {
            id: 7,
            type: 'file',
            from: 'seller',
            text: 'Фото коробки.jpg'
        },
        {
            id: 8,
            type: 'status',
            from: 'seller',
            statusKey: 'success',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                invoice: 8000,
                amount: 75000,
                currency: '₸',
                login: 'user_alpha',
                city: 'Алматы',
                files: ['https://via.placeholder.com/200x120?text=Получение']
            }
        },
        {
            id: 9,
            type: 'status',
            from: 'user',
            statusKey: 'return',
            order: {
                productName: 'Пылесос Xiaomi',
                count: 1,
                invoice: 8000,
                currency: '₸',
                login: 'user_alpha',
                city: 'Алматы',
                address: 'ул. Панфилова, д. 10',
                files: ['https://via.placeholder.com/200x120?text=Повреждение']
            }
        },
        {
            id: 10,
            type: 'text',
            from: 'user',
            text: 'Я хочу вернуть товар, он повреждён.',
        }
    ]);
    const [input, setInput] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (!chat || messages.length === 0) return;

        // Скроллим вниз только если пользователь добавил новое сообщение
        const lastMsg = messages[messages.length - 1];
        if (lastMsg.from === 'seller') {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const sendMessage = () => {
        if (!input.trim()) return;
        const newMessage = {
            id: Date.now(), // уникальный ID, используя текущее время
            type: 'text', // тип сообщения
            from: 'seller', // отправитель
            text: input, // текст сообщения
            time: new Date().toISOString() // время отправки
        };
        setMessages(prev => [...prev, newMessage]);
        setInput('');
    };

    if (!chat) {
        return <div className={styles.empty}>Выберите заявку слева, чтобы начать общение</div>;
    }

    return (
        <div className={styles.window}>
            <div className={styles.header}>
                <h3>Чат с {chat.name}</h3>
                <div className={styles.statusBadge}>{chat.status}</div>
            </div>

            <div className={styles.messages}>
                {messages.map(msg =>
                    msg.type === 'text' ? (
                        <MessageBubble
                            key={msg.id}
                            text={msg.text}
                            from={msg.from}
                            time={msg.time}
                        />
                    ) : msg.type === 'file' ? (
                        <MessageBubble
                            key={msg.id}
                            text={`📎 Файл: ${msg.text}`}
                            from={msg.from}
                            time={msg.time}
                        />
                    ) : (
                        <StatusBlock
                            key={msg.id}
                            statusKey={msg.statusKey}
                            order={msg.order}
                            from={msg.from}
                        />
                    )
                )}
                <div ref={messagesEndRef} />
            </div>

            <div className={styles.inputAreaWrapper}>
                {/* 😊 Emoji-панель (сверху или снизу) */}
                {showEmoji && (
                    <div className={styles.emojiPicker}>
                        {['😀', '😂', '🔥', '❤️', '👍', '👎'].map((emoji) => (
                            <span
                                key={emoji}
                                className={styles.emoji}
                                onClick={() => setInput((prev) => prev + emoji)}
                            >
                                {emoji}
                            </span>
                        ))}
                    </div>
                )}

                {/* 💬 Поле ввода + кнопки */}
                <div className={styles.inputArea}>
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Написать сообщение..."
                    />

                    <label className={styles.attachButton}>
                        📎
                        <input
                            type="file"
                            hidden
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    setMessages((prev) => [
                                        ...prev,
                                        { id: Date.now(), type: 'file', from: 'seller', text: file.name }
                                    ]);
                                }
                            }}
                        />
                    </label>

                    <button className={styles.iconButton} onClick={() => setShowEmoji(prev => !prev)}>😊</button>

                    <button className={styles.sendButton} onClick={sendMessage}>✈️</button>
                </div>
            </div>
        </div>
    );
};

export default ChatWindow;